/* tslint:disable:variable-name */
export { IBaseApiClient } from '../common/interfaces';
import {
    MediaPlanTargetType,
    IMediaPlan
} from './interfaces';
import {
    IResource
} from '../common/interfaces';

import { BaseEntity, Field, BelongsTo, HasMany } from '../common/meta';
import { Advertiser } from '../advertiser/advertiser.entity';
import { Campaign } from '../campaign/campaign.entity';
import { IAdvertiser } from '../advertiser/interfaces';
import { ICampaign, ConversionEvents } from '../campaign/interfaces';

export class MediaPlan extends BaseEntity<IMediaPlan> implements IMediaPlan {

    public static readonly type: string = 'media_plan';

    @Field()
    id: string;

    @Field()
    name: string;

    @Field()
    media_budget: number;

    @Field()
    campaigns_count: number;

    @Field()
    booked_budget: number;

    @Field()
    budget_pacing: boolean;

    @Field()
    campaigns_start_date: string;

    @Field()
    campaigns_end_date: string;

    @Field()
    remaining_booked_budget: number;

    @Field()
    remaining_booked_budget_yesterday: number;

    @Field()
    agency_fee: number;

    @Field()
    use_platform161_tech_fee: boolean;

    @Field()
    platform161_tech_fee: number;

    @Field()
    unlimited_budget: boolean;

    @Field()
    start_on: string;

    @Field()
    end_on: string;

    @Field()
    favorite: boolean;

    @Field()
    active?: boolean;

    @Field()
    frequency_cap: number;

    @Field()
    frequency_cap_type: string;

    @Field()
    frequency_cap_count: number;

    @Field()
    secondary_frequency_cap: number;

    @Field()
    secondary_frequency_cap_type: string;

    @Field()
    secondary_frequency_cap_count: number;

    @Field()
    ctr: number;

    @Field()
    ecpa: number;

    @Field()
    ecpm: number;

    @Field()
    ecpc: number;

    @Field()
    impressions: number;

    @Field()
    clicks: number;

    @Field()
    dma: boolean;

    @Field()
    cid: string;

    @Field()
    chid: string;

    @Field()
    external_id: string;

    @Field()
    client_order_id: number;

    @Field()
    flight_on_time_percentage: number;

    @Field()
    total_booked_budget_spent: number;

    @Field()
    alert_on_campaigns: boolean;

    @Field()
    conversions: number;

    @Field()
    remaining_media_budget: number;

    @Field()
    on_time_percentage_yesterday: number;

    @Field()
    total_cost_ecpm: number;

    @Field()
    total_cost_ecpa: number;

    @Field()
    total_cost_ecpc: number;

    @Field()
    total_spend_yesterday: number;

    @Field()
    total_on_time_percentage_yesterday: number;

    @Field()
    target_value: number;

    @Field()
    target_type: MediaPlanTargetType;

    @Field()
    current_value_for_target: number;

    @Field()
    spend_yesterday: number;

    @Field()
    summed_budget: number;

    @Field()
    under_over_delivery: number;

    @Field()
    total_media_budget_spent: number;

    @Field()
    projected_spend: number;

    @Field()
    optimal_spend_today: number;

    @Field()
    optimal_spend_yesterday: number;

    @Field()
    total_optimal_spend_yesterday: number;

    @Field()
    has_landing_campaigns: boolean;

    @Field()
    has_unlimited_budget_campaigns?: boolean;

    @Field()
    total_campaigns_budget_spent: number;

    @Field()
    total_campaigns_budget_spent_yesterday: number;

    @Field()
    days_to_go: number;

    @Field()
    conversion_events?: ConversionEvents;

    @Field()
    has_ever_activated_campaigns?: boolean;

    @BelongsTo({ deferredConstructor: () => Advertiser })
    advertiser: IAdvertiser | IResource;

    @HasMany({ deferredConstructor: () => Campaign })
    campaigns: ICampaign[] | IResource[];

}
