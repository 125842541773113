import { Component, OnInit } from '@angular/core';
import { IdentityStorage } from '@modules/common/service/storage/identity-storage';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.template.html',
    host: {
        class: 'error-page app'
    },
    providers: [IdentityStorage]
})
export class NotFoundComponent implements OnInit {
    loginLogo: string;

    constructor(
        private identityStorageService: IdentityStorage
    ) {}

    ngOnInit() {
        this.loginLogo = this.identityStorageService.getErrorLogo();
    }
}
