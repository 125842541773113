<div class="row error-container align-items-center justify-content-center">
    <header>
        <img [src]="loginLogo">
    </header>
    <div class="error-body align-items-center justify-content-center">
        <span class="error-num">Error 403</span>
        <h1>It seems that you do not have permissions to access this page.</h1>
        <div class="error-text">
            Please reach out to your Account Manager if you think this should not be the case.
        </div>
        <a class="btn-cancel" [routerLink]="['/login']">Homepage</a>
    </div>
    <footer class="align-items-center justify-content-center">
        <a href="https://verve.com/contact/" target="_blank">Contact us</a>
        <span>©Verve 2024</span>
    </footer>
</div>