import { Component, OnInit } from '@angular/core';
import { IdentityStorage } from '@modules/common/service/storage/identity-storage';

@Component({
    selector: 'forbidden',
    templateUrl: './forbidden.template.html',
    host: {
        class: 'error-page app'
    },
    providers: [IdentityStorage]
})
export class ForbiddenComponent implements OnInit {
    loginLogo: string;

    constructor(
        private identityStorageService: IdentityStorage
    ) {}

    ngOnInit() {
        this.loginLogo = this.identityStorageService.getErrorLogo();
    }
}
