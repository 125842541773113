/* tslint:disable:variable-name */
import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthHttp } from '../authentication/authentication.service';
import { environment } from 'environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ErrorResponse } from './../common/errors';

@Injectable()
export class PasswordResetService {
    constructor(private http: AuthHttp) {}

    setNewPassword(instanceCode: string, password: string, token: string) {
        return this.http.patch(
            `${environment.api.base}/${instanceCode}/user_reset_tokens/${token}`,
            {
                'password': password,
                'password_confirmation': password
            },
            { withCredentials: false }
        )
        .pipe(
            map((response: HttpResponse<any>) => {
                return response;
            }),
            catchError(this.handleRequestError)
        );
    }
    validateToken(instanceCode: string, token: string) {
        return this.http.get(
            `${environment.api.base}/${instanceCode}/user_reset_tokens/${token}/validate`,
            { withCredentials: false }
        );
    }

    protected handleRequestError(response: any): Observable<any> {
        if (response instanceof HttpErrorResponse) {
            let errors = response.error.errors || [];
            if ('password' in errors) {
                errors = [{'title': errors['password'].join(' ')}];
            }
            return throwError(new ErrorResponse(response.status, errors));
        }

        return throwError(response);
    }
}
