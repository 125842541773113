import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';

import {
    Impersonation, INSTANCE_KEY, IMPERSONATE_KEY, IUser, IInstance, IInstanceCodeClient, VisibleStrategyCard,
    IRole, IRoleTemplate, IUserPreference
} from '../../../../sdk.index';
import * as moment from 'moment';

export const USER_KEY = 'current-user';
export const LOGO = 'logo_';
export const IMPERSONATING_USER_KEY = 'impersonating-user';
const DEFAULT_BACKGROUND_IMAGE_URI = '/assets/img/login/blue_shape.svg';
const VERVE_BACKGROUND_IMAGE_URI = '/assets/img/login/verve_back_logo.svg';

export interface ILoggedInUser {
    login: string;
    first_name: string;
    last_name: string;
    email: string;
    id?: string;
    is_superadmin: boolean;
    is_external: boolean;
    is_client: boolean;
    reporting_permission: string;
}

export interface IInstanceData {
    id?: string;
    name: string;
    code: string;
    locale: string;
    currency_locale: string;
    time_zone: string;
    currency: string;
    date_locale: string;
    budget_spend: string;
    visible_strategy_cards: VisibleStrategyCard[];
    optimized_margin_fee_percentage: number;
    video_visibility_values: string[];
    display_visibility_values: string[];
    reporting_package: string;
    reporting_package_values: {[key: string]: any}[];
    first_weekday: number;
    open_auction_allowed: string;
    has_appnexus_inventory: boolean;
    has_google_inventory: boolean;
    has_viooh_inventory: boolean;
    has_broadsign_inventory: boolean;
    appnexus_connection: 'default' | 'direct' | 'bidswitch';
    support_tier?: string;
    default_radius: string;
    distance_unit: string;
    has_campaigns_older_than_180_days: boolean;
}

@Injectable()
export class IdentityStorage implements IInstanceCodeClient {
    private impersonateService: Impersonation;
    private subject = new Subject<any>();

    constructor(
        private router: Router,
        private _route: ActivatedRoute
    ) {
        this.impersonateService = new Impersonation();
    }

    getImpersonatedUser(): string | null {
        return localStorage.getItem(IMPERSONATE_KEY);
    }

    setImpersonatedUser(userId: string): void {
        this.impersonateService.start(userId);
    }

    clearImpersonatedUser(): void {
        this.impersonateService.stop();
    }

    getImpersonatingUser(): string {
        return localStorage.getItem(IMPERSONATING_USER_KEY);
    }

    setImpersonatingUser(userId: string): void {
        localStorage.setItem(IMPERSONATING_USER_KEY, userId);
    }

    clearImpersonatingUser(): void {
        localStorage.removeItem(IMPERSONATING_USER_KEY);
    }

    getInstanceCode(): string {
        let whiteLabelInstanceCode = this.getWhiteLabelInstanceCode();
        if (whiteLabelInstanceCode) return whiteLabelInstanceCode;
        let instance = this.getInstance();
        return instance ? instance.code : null;
    }

    getReportingMinDate(): Date {
        const days =  this.getReportingPackage().value;
        return days ? moment().subtract(days, 'days').startOf('day').toDate() : null;
    }

    getReportingPackage(): {[key: string]: any} {
        const instance = this.getInstance();
        if (!instance.reporting_package_values) {
            return {
                id: instance.reporting_package,
                label: instance.reporting_package.replace('_', ' '),
                value: null
            };
        }

        return instance.reporting_package_values
            .find(value => value.id === instance.reporting_package);
    }

    // Use it to get the logo when already logged in
    getInstanceLogo(): string {
        return this.getWhiteLabelLoginLogo() || this.getLogoFor(this.getInstanceCode());
    }

    // Use this one when not logged in (like in login or restore password pages)
    getLoginLogo(): string {
        return this.getWhiteLabelLoginLogo() || this.getLogoFor(this.instanceCodeFromRoute());
    }

    getErrorLogo(): string {
        return this.getWhiteLabelLoginLogo() || '/assets/img/login/verve_back_logo.svg';
    }
    // If the instance has no logo in the backend, looks into the whitelabel config
    // or returns the generic logo
    getFallbackLoginLogo(): string {
        return this.getWhiteLabelLoginLogo() || '/assets/img/logo_hd.svg';
    }

    // Called when an IMG tag for a logo fails to load the image.
    // Tries to replace the previous logo with the fallback
    handleMissingLogo(event) {
        let fallBackLoginLogo = this.getFallbackLoginLogo();
        let currentSrc = event.target.src;
        if (!currentSrc) return;
        event.target.src = fallBackLoginLogo;
        // We check this in order to avoid an infinite loop if the fallback logo fails, too
        if (event.target.src === currentSrc) event.target.removeAttribute('src');
    }

    getLoginLogoAltText(): string {
        return this.getWhiteLabelLoginLogoAltText() || 'Platform 161';
    }

    setDefaultLogo(): void {
        localStorage.setItem(LOGO + this.getInstanceCode(), '/assets/img/logo_hd.svg');
    }

    isCostBudgetSpentInstance(): boolean {
        return this.getInstance().budget_spend === 'cost';
    }

    getInstance(): IInstanceData {
        let instanceDataFromSession = <IInstanceData>JSON.parse(sessionStorage.getItem(INSTANCE_KEY));
        let instanceData = <IInstanceData>JSON.parse(localStorage.getItem(INSTANCE_KEY));
        return instanceDataFromSession || instanceData;
    }

    getInstanceAsObservable(): Observable<IInstanceData> {
        return this.subject.asObservable();
    }

    getInstanceFromSession(): IInstanceData {
        return <IInstanceData>JSON.parse(sessionStorage.getItem(INSTANCE_KEY));
    }

    getWhiteLabelData(): object {
        let instanceCode = this.getInstanceCodeFromUrl();
        let host = window.location.hostname;
        if (!host) return null;
        let data = environment &&
            environment.whiteLabelHosts &&
            environment.whiteLabelHosts[host];
        data = data && data[instanceCode] ? {...data, ...data[instanceCode]} : data;
        return data;
    }

    // checks if current instance is whitelabeled
    isInstanceWhiteLabeled(): boolean {
       return Object.values(environment && environment.whiteLabelHosts || {})
           .map(config => config.instanceCode).indexOf(this.getInstanceCode()) > -1;
    }

    // checks if app is running on whitelabel domain name
    isWhiteLabelHost(): boolean {
        return !!this.getWhiteLabelData();
    }

    getWhiteLabelHostname(): string {
        let whiteLabelData = this.getWhiteLabelData();
        return whiteLabelData && whiteLabelData['hostname'];
    }

    getWhiteLabelInstanceCode(): string {
        let whiteLabelData = this.getWhiteLabelData();
        return whiteLabelData && whiteLabelData['instanceCode'];
    }

    getWhiteLabelLoginLogo(): string {
        let whiteLabelData = this.getWhiteLabelData();
        return whiteLabelData && whiteLabelData['loginLogo'];
    }

    getWhiteLabelLoginLogoAltText(): string {
        let whiteLabelData = this.getWhiteLabelData();
        return whiteLabelData && whiteLabelData['loginLogoAltText'];
    }

    setInstance(instance: IInstance, saveInSession?: boolean): void {
        let instanceData: IInstanceData;
        instanceData = {
            id: instance.id,
            name: instance.name,
            currency: instance.currency,
            // @todo uncomment when instances will have different locales
            // locale: instance.locale,
            locale: 'en-GB',
            currency_locale: 'en-GB',
            time_zone: instance.time_zone,
            code: instance.code,
            date_locale: instance.date_locale || 'en-GB',
            budget_spend: instance.budget_spend,
            visible_strategy_cards: instance.visible_strategy_cards || [],
            optimized_margin_fee_percentage: instance.optimized_margin_fee_percentage,
            video_visibility_values: instance.video_visibility_values || [],
            display_visibility_values: instance.display_visibility_values || [],
            reporting_package: instance.reporting_package || 'lifetime',
            reporting_package_values: instance.reporting_package_values,
            first_weekday: instance.first_weekday || 0,
            open_auction_allowed: instance.open_auction_allowed,
            has_appnexus_inventory: instance.has_appnexus_inventory,
            has_google_inventory: instance.has_google_inventory,
            has_viooh_inventory: instance.has_viooh_inventory,
            has_broadsign_inventory: instance.has_broadsign_inventory,
            appnexus_connection: instance.appnexus_connection,
            support_tier: instance.support_tier,
            default_radius: instance.default_radius,
            distance_unit: instance.distance_unit,
            has_campaigns_older_than_180_days: instance.has_campaigns_older_than_180_days
        };

        if (saveInSession !== true) {
            localStorage.setItem(INSTANCE_KEY, JSON.stringify(instanceData));
        }
        sessionStorage.setItem(INSTANCE_KEY, JSON.stringify(instanceData));

        this.subject.next(instanceData);
    }

    clearInstance(): void {
        localStorage.removeItem(INSTANCE_KEY);
    }

    getUser(): ILoggedInUser {
        let user = <ILoggedInUser>JSON.parse(localStorage.getItem(USER_KEY));
        if (!user) {
            let instanceFromRoute = this.instanceCodeFromRoute();
            location.hash = '/' + instanceFromRoute + '/login';
            location.reload();
        }
        return user;
    }

    hasUser(): boolean {
        try {
            this.getUser();
        } catch (e) {
            return false;
        }

        return true;
    }

    setUser(user: IUser): void {
        this.preparePreferences(user);
        let isClient = true;

        (<IRole[]>user.roles).forEach((role: IRole) => {
            if ((<IRoleTemplate>role.role_template) && (<IRoleTemplate>role.role_template).kind !== 'client') {
                isClient = false;
            }
        });

        let loggedInUser: ILoggedInUser = {
            id: user.id || null,
            login: user.login,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            is_superadmin: user.is_superadmin,
            is_external: user.is_advertiser || user.is_agency,
            is_client: isClient,
            reporting_permission: user.reporting_permission
        };
        localStorage.setItem(USER_KEY, JSON.stringify(loggedInUser));
    }

    clearUser() {
        localStorage.removeItem(USER_KEY);
    }

    clearAll(): void {
        this.clearInstance();
        this.clearImpersonatedUser();
        this.clearUser();
    }

    preparePreferences(user: IUser): void {
        (user.user_preferences as IUserPreference[]).forEach((preference) => {
            localStorage.setItem(preference.scope, JSON.stringify(preference.data));
        });
    }

    getDateFormat(): string {
        let dateFormat = 'DD/MM/yyyy HH:mm';
        if (this.getInstance()['date_locale'] === 'en-US') {
            dateFormat = 'MM/DD/yyyy HH:mm';
        }
        return dateFormat;
    }

    resolveBackgroundImage(): string {
        if (this.isInstanceWhiteLabeled()) {
            return '';
        }
        if (this.usesCustomLogo()) {
            return VERVE_BACKGROUND_IMAGE_URI;
        } else {
            return DEFAULT_BACKGROUND_IMAGE_URI;
        }
    }

    private usesCustomLogo(): boolean {
        let instanceLogo = this.getLogoFor(this.instanceCodeFromRoute());
        if ((instanceLogo.indexOf('login_logo_files') > -1) ||
            (instanceLogo.indexOf('logo_hd.svg') < 0)) {
                return true;
        }
        return false;
    }

    private instanceCodeFromRoute(): any {
        let instanceFromUrl = this.getInstanceCodeFromUrl();
        let instanceFromStoredRoute = this._route.snapshot.firstChild &&
            this._route.snapshot.firstChild.params.instance;
        return instanceFromUrl || instanceFromStoredRoute || this.getInstanceCode();
    }

    private getInstanceCodeFromUrl(): string {
        let url = this.router.url && this.router.url.split('/');
        return url && url.length > 2 && url[1];
    }

    private getLogoFor(instanceCode): string {
        const logo = localStorage.getItem(LOGO + instanceCode);
        if (logo) {
            return logo;
        }
        return `${environment.api.host}/${instanceCode}/login_logo_files`;
    }
}
